<template>
  <div class="page-body">
    <div class="card">
      <div class="table-responsive">
        <table class="table card-table">
          <thead>
            <tr style="border-bottom: 1px solid #000;">
              <th>Email</th>
              <th>Role</th>
              <!-- <th>Created At</th>
              <th>Updated At</th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user of users" :key="user.ID">
              <td>
                <div>
                  {{ user.Email }}
                </div>
              </td>
              <td>
                <button class="badge bg-red-lt" v-for="role of user.Role" :key="role.id"
                  v-on:click="delRole(user.Email, role.role)">{{ role.role }}<span>&#10006;</span></button>
                <select @change="addRole(user.Email, $event)">
                  <option disabled selected></option>
                  <option v-for="role of roles" :key="role.id" v-bind:value="role.role">{{ role.role }}</option>
                </select>
              </td>
              <!-- <td>
                <div>
                  {{new Date(user.CreatedAt)}}
                </div>
              </td>
              <td>
                <div>
                  {{new Date(user.UpdatedAt)}}
                </div>
              </td> -->
              <td>
                <div>
                  <button class="btn btn-danger" v-on:click="delUser(user.Email, user.ID)">Delete</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'admin',
	data () {
		return {
			users: [],
			roles: []
		}
	},
	methods: {
		...mapActions(['getUsers', 'getRoles', 'setRole', 'deleteUser', 'removeRole']),
		loadUsers () {
			this.getUsers().then((data) => {
				this.users = data.Data
			})
		},
		loadRoles () {
			this.getRoles().then((data) => {
				this.roles = data.Data
			})
		},
		async addRole (email, event) {
			await this.setRole({ Email: email, Role: event.target.value })
			this.loadUsers()
		},
		async delRole (email, role) {
			await this.removeRole({ Email: email, Role: role })
			this.loadUsers()
		},
		async delUser (name, id) {
			const res = await this.$swal.fire({
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, cancel!',
				text: 'Do you really want to delete ' + name + '?'
			})
			if (!res.isConfirmed) {
				return
			}
			await this.deleteUser({ ID: id })
			this.loadUsers()
		}
	},
	async mounted () {
		await this.loadUsers()
		this.loadRoles()
	}
}
</script>
